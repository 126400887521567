@charset "UTF-8";
:root {
  --version: 1.39;
}

.la-ball-spin,
.la-ball-spin > div {
  position: relative;
  box-sizing: border-box;
}

.la-ball-spin {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-spin.la-dark {
  color: #333;
}

.la-ball-spin > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-spin {
  width: 32px;
  height: 32px;
}

.la-ball-spin > div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
  border-radius: 100%;
  -webkit-animation: ball-spin 1s infinite ease-in-out;
  animation: ball-spin 1s infinite ease-in-out;
}

.la-ball-spin > div:nth-child(1) {
  top: 5%;
  left: 50%;
  -webkit-animation-delay: -1.125s;
  animation-delay: -1.125s;
}

.la-ball-spin > div:nth-child(2) {
  top: 18.1801948466%;
  left: 81.8198051534%;
  -webkit-animation-delay: -1.25s;
  animation-delay: -1.25s;
}

.la-ball-spin > div:nth-child(3) {
  top: 50%;
  left: 95%;
  -webkit-animation-delay: -1.375s;
  animation-delay: -1.375s;
}

.la-ball-spin > div:nth-child(4) {
  top: 81.8198051534%;
  left: 81.8198051534%;
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.la-ball-spin > div:nth-child(5) {
  top: 94.9999999966%;
  left: 50.0000000005%;
  -webkit-animation-delay: -1.625s;
  animation-delay: -1.625s;
}

.la-ball-spin > div:nth-child(6) {
  top: 81.8198046966%;
  left: 18.1801949248%;
  -webkit-animation-delay: -1.75s;
  animation-delay: -1.75s;
}

.la-ball-spin > div:nth-child(7) {
  top: 49.9999750815%;
  left: 5.0000051215%;
  -webkit-animation-delay: -1.875s;
  animation-delay: -1.875s;
}

.la-ball-spin > div:nth-child(8) {
  top: 18.179464974%;
  left: 18.1803700518%;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.la-ball-spin.la-sm {
  width: 16px;
  height: 16px;
}

.la-ball-spin.la-sm > div {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}

.la-ball-spin.la-2x {
  width: 64px;
  height: 64px;
}

.la-ball-spin.la-2x > div {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}

.la-ball-spin.la-3x {
  width: 96px;
  height: 96px;
}

.la-ball-spin.la-3x > div {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
}

@-webkit-keyframes ball-spin {
  0%, 100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes ball-spin {
  0%, 100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
.breadcrumb {
  flex-wrap: nowrap;
}
.breadcrumb .breadcrumb-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: inherit !important;
}
.breadcrumb .breadcrumb-item a {
  text-decoration: underline;
  color: #a7afc3;
}

.scrollable-breadcrumb-wrapper {
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;
}
.scrollable-breadcrumb-wrapper::-webkit-scrollbar {
  display: none;
}

.breadcrumb-light-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.7)), color-stop(50%, rgba(255, 255, 255, 0.2)), color-stop(75%, rgba(255, 255, 255, 0.1)), to(transparent));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 100%);
  height: 280px;
  position: relative;
  z-index: 1010;
}
.breadcrumb-light-gradient .breadcrumb {
  text-shadow: rgba(255, 255, 255, 0.5) 0 1px 15px;
}
.breadcrumb-light-gradient .breadcrumb .breadcrumb-item a {
  color: #063462;
}

.badge-sm {
  padding: 0.35em 0.65em !important;
  font-size: 0.75em !important;
  font-weight: 700 !important;
}

.btn-group-toggle:not(.new) input[type=radio].invisible + label.btn .unselected,
.btn-group-toggle:not(.new) input[type=radio].opacity-0 + label.btn .unselected,
.btn-group-toggle:not(.new) input[type=checkbox].invisible + label.btn .unselected,
.btn-group-toggle:not(.new) input[type=checkbox].opacity-0 + label.btn .unselected {
  display: block;
}
.btn-group-toggle:not(.new) input[type=radio].invisible + label.btn .selected,
.btn-group-toggle:not(.new) input[type=radio].opacity-0 + label.btn .selected,
.btn-group-toggle:not(.new) input[type=checkbox].invisible + label.btn .selected,
.btn-group-toggle:not(.new) input[type=checkbox].opacity-0 + label.btn .selected {
  display: none;
}
.btn-group-toggle:not(.new) input[type=radio].invisible:checked + label.btn.border-midnightblue,
.btn-group-toggle:not(.new) input[type=radio].opacity-0:checked + label.btn.border-midnightblue,
.btn-group-toggle:not(.new) input[type=checkbox].invisible:checked + label.btn.border-midnightblue,
.btn-group-toggle:not(.new) input[type=checkbox].opacity-0:checked + label.btn.border-midnightblue {
  box-shadow: inset 0 0 0 2px #042342;
}
.btn-group-toggle:not(.new) input[type=radio].invisible:checked + label.btn .unselected,
.btn-group-toggle:not(.new) input[type=radio].opacity-0:checked + label.btn .unselected,
.btn-group-toggle:not(.new) input[type=checkbox].invisible:checked + label.btn .unselected,
.btn-group-toggle:not(.new) input[type=checkbox].opacity-0:checked + label.btn .unselected {
  display: none !important;
}
.btn-group-toggle:not(.new) input[type=radio].invisible:checked + label.btn .selected,
.btn-group-toggle:not(.new) input[type=radio].opacity-0:checked + label.btn .selected,
.btn-group-toggle:not(.new) input[type=checkbox].invisible:checked + label.btn .selected,
.btn-group-toggle:not(.new) input[type=checkbox].opacity-0:checked + label.btn .selected {
  display: block !important;
}

.btn-group-toggle.new input[type=radio].invisible + label.btn,
.btn-group-toggle.new input[type=radio].opacity-0 + label.btn,
.btn-group-toggle.new input[type=checkbox].invisible + label.btn,
.btn-group-toggle.new input[type=checkbox].opacity-0 + label.btn {
  color: #042342;
  background: #ffffff;
}
.btn-group-toggle.new input[type=radio].invisible + label.btn .font-minimal-info,
.btn-group-toggle.new input[type=radio].opacity-0 + label.btn .font-minimal-info,
.btn-group-toggle.new input[type=checkbox].invisible + label.btn .font-minimal-info,
.btn-group-toggle.new input[type=checkbox].opacity-0 + label.btn .font-minimal-info {
  color: inherit;
}
.btn-group-toggle.new input[type=radio].invisible + label.btn .unselected,
.btn-group-toggle.new input[type=radio].opacity-0 + label.btn .unselected,
.btn-group-toggle.new input[type=checkbox].invisible + label.btn .unselected,
.btn-group-toggle.new input[type=checkbox].opacity-0 + label.btn .unselected {
  display: block;
  color: #a7afc3;
}
.btn-group-toggle.new input[type=radio].invisible + label.btn .selected,
.btn-group-toggle.new input[type=radio].opacity-0 + label.btn .selected,
.btn-group-toggle.new input[type=checkbox].invisible + label.btn .selected,
.btn-group-toggle.new input[type=checkbox].opacity-0 + label.btn .selected {
  display: none;
}
.btn-group-toggle.new input[type=radio].invisible:checked + label.btn.border-grey1,
.btn-group-toggle.new input[type=radio].opacity-0:checked + label.btn.border-grey1,
.btn-group-toggle.new input[type=checkbox].invisible:checked + label.btn.border-grey1,
.btn-group-toggle.new input[type=checkbox].opacity-0:checked + label.btn.border-grey1 {
  background: #3e6a91 !important;
  color: #ffffff !important;
  border-color: #3e6a91 !important;
}
.btn-group-toggle.new input[type=radio].invisible:checked + label.btn.border-grey1 .font-minimal-info,
.btn-group-toggle.new input[type=radio].opacity-0:checked + label.btn.border-grey1 .font-minimal-info,
.btn-group-toggle.new input[type=checkbox].invisible:checked + label.btn.border-grey1 .font-minimal-info,
.btn-group-toggle.new input[type=checkbox].opacity-0:checked + label.btn.border-grey1 .font-minimal-info {
  color: inherit;
}
.btn-group-toggle.new input[type=radio].invisible:checked + label.btn .unselected,
.btn-group-toggle.new input[type=radio].opacity-0:checked + label.btn .unselected,
.btn-group-toggle.new input[type=checkbox].invisible:checked + label.btn .unselected,
.btn-group-toggle.new input[type=checkbox].opacity-0:checked + label.btn .unselected {
  display: none !important;
}
.btn-group-toggle.new input[type=radio].invisible:checked + label.btn .selected,
.btn-group-toggle.new input[type=radio].opacity-0:checked + label.btn .selected,
.btn-group-toggle.new input[type=checkbox].invisible:checked + label.btn .selected,
.btn-group-toggle.new input[type=checkbox].opacity-0:checked + label.btn .selected {
  display: block !important;
}

.form-control {
  min-height: 2.5rem;
}

.form-control-sm {
  min-height: 2.125rem !important;
}

.form-control-lg {
  min-height: 3.75rem !important;
}

.form-check-input:hover:not(:checked):not(:invalid):not(:valid),
.form-control:hover:not(:checked):not(:invalid):not(:valid),
.form-select:hover:not(:checked):not(:invalid):not(:valid) {
  border-color: #a7afc3;
}

form,
.formlike {
  position: relative;
}
form .form-control.text-primary::-webkit-input-placeholder, .formlike .form-control.text-primary::-webkit-input-placeholder {
  color: #063462;
  opacity: 1;
}
form .form-control.text-primary::-moz-placeholder, .formlike .form-control.text-primary::-moz-placeholder {
  color: #063462;
  opacity: 1;
}
form .form-control.text-primary::placeholder,
.formlike .form-control.text-primary::placeholder {
  color: #063462;
  opacity: 1;
}
form label.form-check-label,
.formlike label.form-check-label {
  padding-left: 0.5rem;
}
form label:not(.form-check-label).required:after,
form legend.col-form-label.required:after,
.formlike label:not(.form-check-label).required:after,
.formlike legend.col-form-label.required:after {
  content: " *";
}
form label.form-label,
form .col-form-label,
.formlike label.form-label,
.formlike .col-form-label {
  float: none !important;
  color: #063462 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  padding-top: 0;
  margin-bottom: 0.5rem;
  padding-bottom: 0;
  line-height: 1.25;
}
form label.form-label.custom-file-label,
form .col-form-label.custom-file-label,
.formlike label.form-label.custom-file-label,
.formlike .col-form-label.custom-file-label {
  color: #a7afc3 !important;
}
form label.switch-label,
.formlike label.switch-label {
  margin-bottom: 0;
  font-size: 1rem !important;
  font-weight: 400 !important;
}
form select[data-route][multiple] option:checked,
.formlike select[data-route][multiple] option:checked {
  opacity: 0;
}
form input[type=search],
.formlike input[type=search] {
  background-clip: padding-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  background: transparent;
  position: relative;
  padding-right: -webkit-calc(1.25em + 1.125rem);
  padding-right: calc(1.25em + 1.125rem);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"1em\" height=\"1em\" viewBox=\"0 0 16 16\" style=\"fill: %23e5e7ed\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z\"/></svg>");
  background-position: right -webkit-calc(0.3125em + 0.28125rem) center;
  background-position: right calc(0.3125em + 0.28125rem) center;
  -webkit-transition: background-image 250ms ease;
  transition: background-image 250ms ease;
}
form input[type=search]::-webkit-search-decoration, form input[type=search]::-webkit-search-cancel-button, form input[type=search]::-webkit-search-results-button, form input[type=search]::-webkit-search-results-decoration,
.formlike input[type=search]::-webkit-search-decoration,
.formlike input[type=search]::-webkit-search-cancel-button,
.formlike input[type=search]::-webkit-search-results-button,
.formlike input[type=search]::-webkit-search-results-decoration {
  display: none;
}
form input[type=search]:hover,
.formlike input[type=search]:hover {
  background-image: url("data:image/svg+xml;utf8,<svg width=\"1em\" height=\"1em\" viewBox=\"0 0 16 16\" style=\"fill: %23a7afc3\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z\"/></svg>");
}
form input[type=search]:focus,
.formlike input[type=search]:focus {
  background-image: url("data:image/svg+xml;utf8,<svg width=\"1em\" height=\"1em\" viewBox=\"0 0 16 16\" style=\"fill: %23063462\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z\"/></svg>");
}
form select.form-control + select.form-control,
.formlike select.form-control + select.form-control {
  margin-left: 0.75rem;
}
form input[type=radio] + label,
form input[type=checkbox] + label,
.formlike input[type=radio] + label,
.formlike input[type=checkbox] + label {
  font-weight: 400 !important;
  line-height: 1.2 !important;
}
form input[type=date],
form input.datepicker-input,
.formlike input[type=date],
.formlike input.datepicker-input {
  position: relative;
  padding-left: -webkit-calc(1.25em + 1.125rem);
  padding-left: calc(1.25em + 1.125rem);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"1em\" height=\"1em\" viewBox=\"0 0 16 16\" style=\"fill: %23A7AFC3\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" d=\"M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z\"/> <path d=\"M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z\"/></svg>");
  background-position: left -webkit-calc(0.3125em + 0.28125rem) center;
  background-position: left calc(0.3125em + 0.28125rem) center;
  /* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
  /* adjust clear button */
  /* adjust increase/decrease button */
}
form input[type=date]::-webkit-calendar-picker-indicator,
form input.datepicker-input::-webkit-calendar-picker-indicator,
.formlike input[type=date]::-webkit-calendar-picker-indicator,
.formlike input.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: -24px;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  width: 25px;
  background: transparent;
}
form input[type=date]::-webkit-clear-button,
form input.datepicker-input::-webkit-clear-button,
.formlike input[type=date]::-webkit-clear-button,
.formlike input.datepicker-input::-webkit-clear-button {
  z-index: 1;
}
form input[type=date]::-webkit-inner-spin-button,
form input.datepicker-input::-webkit-inner-spin-button,
.formlike input[type=date]::-webkit-inner-spin-button,
.formlike input.datepicker-input::-webkit-inner-spin-button {
  z-index: 1;
}
form.was-validated input[type=date]:valid, form.was-validated input[type=date].is-valid,
form.was-validated input.datepicker-input:valid,
form.was-validated input.datepicker-input.is-valid,
form .was-validated input[type=date]:valid,
form .was-validated input[type=date].is-valid,
form .was-validated input.datepicker-input:valid,
form .was-validated input.datepicker-input.is-valid,
.formlike.was-validated input[type=date]:valid,
.formlike.was-validated input[type=date].is-valid,
.formlike.was-validated input.datepicker-input:valid,
.formlike.was-validated input.datepicker-input.is-valid,
.formlike .was-validated input[type=date]:valid,
.formlike .was-validated input[type=date].is-valid,
.formlike .was-validated input.datepicker-input:valid,
.formlike .was-validated input.datepicker-input.is-valid {
  background-image: url("data:image/svg+xml;utf8,<svg width=\"1em\" height=\"1em\" viewBox=\"0 0 16 16\" fill=\"%233e9187\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" d=\"M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z\"/> <path d=\"M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z\"/></svg>");
}
form.was-validated input[type=date]:invalid, form.was-validated input[type=date].is-invalid,
form.was-validated input.datepicker-input:invalid,
form.was-validated input.datepicker-input.is-invalid,
form .was-validated input[type=date]:invalid,
form .was-validated input[type=date].is-invalid,
form .was-validated input.datepicker-input:invalid,
form .was-validated input.datepicker-input.is-invalid,
.formlike.was-validated input[type=date]:invalid,
.formlike.was-validated input[type=date].is-invalid,
.formlike.was-validated input.datepicker-input:invalid,
.formlike.was-validated input.datepicker-input.is-invalid,
.formlike .was-validated input[type=date]:invalid,
.formlike .was-validated input[type=date].is-invalid,
.formlike .was-validated input.datepicker-input:invalid,
.formlike .was-validated input.datepicker-input.is-invalid {
  background-image: url("data:image/svg+xml;utf8,<svg width=\"1em\" height=\"1em\" viewBox=\"0 0 16 16\" fill=\"%23ff3944\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" d=\"M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z\"/> <path d=\"M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z\"/></svg>");
}
form.was-validated input[data-reveal-password].is-invalid, form.was-validated input[data-reveal-password]:invalid,
form .was-validated input[data-reveal-password].is-invalid,
form .was-validated input[data-reveal-password]:invalid,
.formlike.was-validated input[data-reveal-password].is-invalid,
.formlike.was-validated input[data-reveal-password]:invalid,
.formlike .was-validated input[data-reveal-password].is-invalid,
.formlike .was-validated input[data-reveal-password]:invalid {
  padding-right: 3.9375rem;
}
form.was-validated input[data-reveal-password].is-invalid + .reveal-button svg, form.was-validated input[data-reveal-password]:invalid + .reveal-button svg,
form .was-validated input[data-reveal-password].is-invalid + .reveal-button svg,
form .was-validated input[data-reveal-password]:invalid + .reveal-button svg,
.formlike.was-validated input[data-reveal-password].is-invalid + .reveal-button svg,
.formlike.was-validated input[data-reveal-password]:invalid + .reveal-button svg,
.formlike .was-validated input[data-reveal-password].is-invalid + .reveal-button svg,
.formlike .was-validated input[data-reveal-password]:invalid + .reveal-button svg {
  fill: #ff3944;
}
form.was-validated input[data-reveal-password].is-valid + .reveal-button svg, form.was-validated input[data-reveal-password]:valid + .reveal-button svg,
form .was-validated input[data-reveal-password].is-valid + .reveal-button svg,
form .was-validated input[data-reveal-password]:valid + .reveal-button svg,
.formlike.was-validated input[data-reveal-password].is-valid + .reveal-button svg,
.formlike.was-validated input[data-reveal-password]:valid + .reveal-button svg,
.formlike .was-validated input[data-reveal-password].is-valid + .reveal-button svg,
.formlike .was-validated input[data-reveal-password]:valid + .reveal-button svg {
  fill: #3e9187;
}
form input[type=number],
.formlike input[type=number] {
  -moz-appearance: textfield;
}
form input[type=number]::-webkit-outer-adjust-hue-button,
.formlike input[type=number]::-webkit-outer-adjust-hue-button {
  -webkit-appearance: none;
  margin: 0;
}
form input[type=number]::-webkit-inner-adjust-hue-button,
.formlike input[type=number]::-webkit-inner-adjust-hue-button {
  -webkit-appearance: none;
  margin: 0;
}
form .form-control.form-file,
.formlike .form-control.form-file {
  position: relative;
  padding-left: -webkit-calc(1.25em + 1.125rem);
  padding-left: calc(1.25em + 1.125rem);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  font-weight: 400 !important;
}
form .form-control.form-file::before,
.formlike .form-control.form-file::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  height: -webkit-calc(-webkit-calc(1.25em + 1.125rem) - 1px);
  height: calc(calc(1.25em + 1.125rem) - 1px);
  width: -webkit-calc(1.25em + 1.125rem);
  width: calc(1.25em + 1.125rem);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"1em\" height=\"1em\" viewBox=\"0 0 16 16\" style=\"fill: %23A7AFC3\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" d=\"M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z\"></path><path fill-rule=\"evenodd\" d=\"M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z\"></path><path fill-rule=\"evenodd\" d=\"M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z\"></path></svg>");
  background-position: left -webkit-calc(0.3125em + 0.28125rem) center;
  background-position: left calc(0.3125em + 0.28125rem) center;
  background-size: -webkit-calc(0.625em + 0.5625rem) -webkit-calc(0.625em + 0.5625rem);
  background-size: calc(0.625em + 0.5625rem) calc(0.625em + 0.5625rem);
}
form .form-control.form-file::after,
.formlike .form-control.form-file::after {
  display: none;
}
form .form-control.form-file + input[type=file],
.formlike .form-control.form-file + input[type=file] {
  z-index: 2;
  margin: 0;
  opacity: 0;
  display: none;
}
form [data-geolocation-wrapper] .geolocation-button,
.formlike [data-geolocation-wrapper] .geolocation-button {
  position: absolute;
  display: -webkit-inline-box;
  display: inline-flex;
  height: 2.5rem;
  line-height: 2.5rem;
  bottom: 0;
  right: 0;
  padding: 0 0.65625rem;
  -webkit-box-align: center;
          align-items: center;
  cursor: pointer;
}
form [data-geolocation-wrapper] .geolocation-button svg,
.formlike [data-geolocation-wrapper] .geolocation-button svg {
  fill: #a7afc3;
}
form input[data-reveal-password],
.formlike input[data-reveal-password] {
  padding-right: 2.625rem;
}
form input[data-reveal-password]::-ms-reveal, form input[data-reveal-password]::-ms-clear,
.formlike input[data-reveal-password]::-ms-reveal,
.formlike input[data-reveal-password]::-ms-clear {
  display: none;
}
form input[data-reveal-password]:focus + .reveal-button svg,
.formlike input[data-reveal-password]:focus + .reveal-button svg {
  fill: #063462 !important;
}
form input[data-reveal-password] + .reveal-button,
.formlike input[data-reveal-password] + .reveal-button {
  position: absolute;
  display: -webkit-inline-box;
  display: inline-flex;
  height: 2.5rem;
  line-height: 2.5rem;
  bottom: 0;
  right: 0;
  padding: 0 0.65625rem;
  -webkit-box-align: center;
          align-items: center;
  cursor: pointer;
}
form input[data-reveal-password] + .reveal-button svg,
.formlike input[data-reveal-password] + .reveal-button svg {
  fill: #a7afc3;
}
form input[data-strength-check],
.formlike input[data-strength-check] {
  -webkit-transition: none;
  transition: none;
}
form input[data-strength-check]:focus + * + .strength-checker-popover,
.formlike input[data-strength-check]:focus + * + .strength-checker-popover {
  border: 1px solid #063462 !important;
  border-top: 0;
  margin-top: -2px;
}
form .strength-checker-popover,
.formlike .strength-checker-popover {
  width: 100%;
  z-index: 100;
  position: absolute;
  background: white;
  padding: 0 1.3125rem 0.5625rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}
form .strength-checker-popover .strength-checker-line,
.formlike .strength-checker-popover .strength-checker-line {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: start;
          justify-content: flex-start;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
form .strength-checker-popover .strength-checker-line.strength-checker-line-valid,
.formlike .strength-checker-popover .strength-checker-line.strength-checker-line-valid {
  color: #3e9187;
}
form .strength-checker-popover .strength-checker-line.strength-checker-line-invalid,
.formlike .strength-checker-popover .strength-checker-line.strength-checker-line-invalid {
  color: #ff3944;
}
form .strength-checker-popover .strength-checker-line span,
.formlike .strength-checker-popover .strength-checker-line span {
  display: -webkit-inline-box;
  display: inline-flex;
}
form .strength-checker-popover .strength-checker-line span svg,
.formlike .strength-checker-popover .strength-checker-line span svg {
  margin-right: 0.5rem;
}

.matrix th {
  max-width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flag-content:before, .flag-content:after {
  left: -9px;
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 10;
}
.flag-content:before {
  top: 0;
  border-width: 15.6px 9px 0 9px;
}
.flag-content:after {
  bottom: 0;
  border-width: 0 9px 15.6px 9px;
}
.flag-content:before {
  border-color: var(--ui-flag-color) transparent transparent transparent;
}
.flag-content:after {
  border-color: transparent transparent var(--ui-flag-color) transparent;
}

.profile-menu {
  min-width: 20.3125rem;
}

.has-menu .profile-menu {
  min-width: 18.4375rem;
}
.has-menu .profile-menu [data-bs-toggle=collapse] {
  pointer-events: none;
}
.has-menu .profile-menu ul,
.has-menu .profile-menu ol {
  display: list-item;
}
.has-menu .profile-menu .collapse-icon {
  display: none !important;
}
.has-menu .profile-menu .expand-icon {
  display: none !important;
}
.has-menu .profile-menu .inline-hidden {
  display: none;
}

.accordion li:hover svg.transition-hover path.fill-color2,
.accordion li:hover svg.transition-hover line.fill-color2,
.accordion li:hover svg.transition-hover circle.fill-color2,
.accordion li:hover svg.transition-hover rect.fill-color2,
.accordion li:hover svg.transition-hover polygon.fill-color2,
.accordion li:hover svg.transition-hover polyline.fill-color2,
.accordion li:hover svg.transition-hover ellipse.fill-color2 {
  fill: #ff3944 !important;
}
.accordion li:hover svg.transition-hover path.stroke-color2,
.accordion li:hover svg.transition-hover line.stroke-color2,
.accordion li:hover svg.transition-hover circle.stroke-color2,
.accordion li:hover svg.transition-hover rect.stroke-color2,
.accordion li:hover svg.transition-hover polygon.stroke-color2,
.accordion li:hover svg.transition-hover polyline.stroke-color2,
.accordion li:hover svg.transition-hover ellipse.stroke-color2 {
  stroke: #ff3944 !important;
}

[data-bs-toggle=collapse] [class*=-icon] {
  display: none;
  margin-right: 1.5rem;
}
[data-bs-toggle=collapse].collapsed .expand-icon {
  display: block;
}
[data-bs-toggle=collapse]:not(.collapsed) .collapse-icon {
  display: block;
}

.nav-default:hover, .nav-default:focus,
.carousel-control-prev-icon:hover,
.carousel-control-prev-icon:focus,
.carousel-control-next-icon:hover,
.carousel-control-next-icon:focus {
  background-color: #063462 !important;
}
.nav-default:hover svg, .nav-default:focus svg,
.carousel-control-prev-icon:hover svg,
.carousel-control-prev-icon:focus svg,
.carousel-control-next-icon:hover svg,
.carousel-control-next-icon:focus svg {
  fill: #ffffff !important;
}

.nav-slider li.active button, .nav-slider li:focus button, .nav-slider li:hover button {
  background-color: #ff3944;
  opacity: 1;
}

.zone-nav [class*=bg-]:after {
  top: 0;
  right: 50%;
  margin-right: -5px;
  margin-top: -10px;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: transparent;
  border-width: 5px;
  border-style: solid;
}
@media (min-width: 768px) {
  .zone-nav [class*=bg-]:after {
    right: 100% !important;
    top: 50% !important;
    margin-top: -5px !important;
    margin-right: 0 !important;
    border-bottom-color: transparent !important;
  }
}
.zone-nav [class*=bg-].bg-primary {
  border-bottom-color: #063462;
}
.zone-nav [class*=bg-].bg-primary:after {
  border-right-color: #063462 !important;
}
.zone-nav [class*=bg-].bg-white {
  border-bottom-color: #ffffff;
}
.zone-nav [class*=bg-].bg-white:after {
  border-right-color: #ffffff !important;
}

.inline-nav {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.switch {
  -webkit-box-flex: 0;
          flex: none;
  border-radius: 999px;
}
.switch .switch-background {
  position: absolute;
  background-color: #cdd6e0;
  height: 100%;
  width: 100%;
}
.switch input:checked + .switch-background {
  background-color: #3e6a91;
}
.switch.big-switch .toggle {
  height: 1.75rem;
  width: 28px;
}
.switch input {
  left: 0;
  opacity: 0;
  top: 0;
  z-index: 2;
}
.switch input:checked {
  z-index: 0;
}
.switch .toggle {
  background: #ffffff;
  border-radius: 50%;
  left: 1px;
  position: absolute;
  top: 1px;
  -webkit-transition: left 0.2s ease;
  transition: left 0.2s ease;
  z-index: 1;
}

.big-switch .toggle {
  height: 1.75rem;
  width: 28px;
}

.switch input:checked ~ .toggle {
  left: 1px;
}

.big-switch input ~ :checked ~ .toggle {
  left: 22px;
}

.switch-label {
  color: #063462;
  font-size: 17px;
  line-height: 20px;
}

.nav-pills .nav-link.active, .nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  color: #063462 !important;
  border-bottom: 1px solid #063462;
  text-decoration: none !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.to-collapse .bi-plus {
  display: none;
}
.to-collapse .bi-dash {
  display: block;
}
.to-collapse.collapsed .bi-plus {
  display: block;
}
.to-collapse.collapsed .bi-dash {
  display: none;
}

[data-bs-toggle=collapse]:not(.collapsed) .hide-on-expanded {
  display: none !important;
}
[data-bs-toggle=collapse].collapsed .hide-on-collapsed {
  display: none !important;
}
.card-header.collapsed {
  margin-bottom: -1px;
}

#timerText {
  font-size: 10em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.bootstrap-touchspin {
  width: 92px;
}
.bootstrap-touchspin .input-group-btn button {
  border-radius: 50%;
  border: 1px soldi #a7afc3;
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0;
  line-height: 2px;
}
.bootstrap-touchspin .form-control {
  width: 40px !important;
  height: 20px !important;
  text-align: center;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
}

.grecaptcha-badge {
  visibility: hidden;
  position: relative;
}

.autocomplete-suggestions {
  background: white;
  position: absolute;
  border: 1px solid #e5e7ed;
  border-radius: 0 0 0.1875rem 0.1875rem !important;
  width: 100%;
  z-index: 983;
  top: 100%;
}
.autocomplete-suggestions:empty {
  display: none;
}

:not(.search-bar) > .autocomplete-suggestions {
  margin-top: -1px;
}
:not(.search-bar) > .autocomplete-suggestions:not(:empty) {
  border-color: #063462 !important;
  border-top-color: #e5e7ed !important;
}
:not(.search-bar) > .autocomplete-suggestions:not(:empty) + * input:focus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .form-control + .autocomplete-suggestions {
  top: 40px;
}
.input-group.input-group-lg .form-control + .autocomplete-suggestions {
  top: 70px !important;
}
.input-group.input-group-lg .form-control.form-control-lg + .autocomplete-suggestions {
  top: 60px !important;
}

input:focus + .autocomplete-suggestions {
  border: 1px solid #063462 !important;
}

.autocomplete-suggestion {
  color: #042342;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.autocomplete-suggestion-highlight {
  background: #e9f4fa;
}

.autocomplete-suggestion-separator {
  font-weight: bold;
  color: #ff3944;
}

.tns-carousel {
  font-size: 1rem !important;
}

.noUi-connect {
  background: #063462 !important;
}

.noUi-horizontal {
  height: 6px !important;
  border: 0 !important;
  background: #e5e7ed !important;
  box-shadow: none !important;
}
.noUi-horizontal .noUi-origin .noUi-handle {
  width: 24px !important;
  height: 24px !important;
  right: -12px !important;
  top: -9px !important;
  cursor: e-resize;
  border-radius: 24px !important;
  box-shadow: 2px 2px 8px rgba(6, 52, 98, 0.1) !important;
  border: 1px solid #e5e7ed;
}
.noUi-horizontal .noUi-origin .noUi-handle:after, .noUi-horizontal .noUi-origin .noUi-handle:before {
  display: none;
}
.noUi-horizontal .noUi-origin .noUi-handle:after {
  display: none;
}

:root {
  --version: 1.39;
}

/* ===============================
=            Choices            =
=============================== */
.choices {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-open {
  overflow: visible;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}

.choices[data-type*=select-one] {
  cursor: pointer;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin: 0;
}
.choices[data-type*=select-one] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.25;
}
.choices[data-type*=select-one] .choices__button:hover, .choices[data-type*=select-one] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0 0 0 2px #00bcd4;
}
.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  display: none;
}
.choices[data-type*=select-one]::after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*=select-one].is-open::after {
  border-color: transparent transparent #333 transparent;
  margin-top: -7.5px;
}
.choices[data-type*=select-one][dir=rtl]::after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*=select-one][dir=rtl] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
  cursor: text;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -5px;
  margin-bottom: 0;
  margin-left: 10px;
  padding-left: 20px;
  border-left: 1px solid #008fa1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*=select-multiple] .choices__button:hover, .choices[data-type*=select-multiple] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b7b7b7;
}
.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.is-active.choices__list--dropdown, .is-active.choices__list[aria-expanded] {
  visibility: visible;
}
.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  border-color: #b7b7b7;
}
.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir=rtl] .choices__list--dropdown .choices__item, [dir=rtl] .choices__list[aria-expanded] .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable::after, .choices__list[aria-expanded] .choices__item--selectable::after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable, [dir=rtl] .choices__list[aria-expanded] .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable::after, [dir=rtl] .choices__list[aria-expanded] .choices__item--selectable::after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
.choices__input::-webkit-search-decoration, .choices__input::-webkit-search-cancel-button, .choices__input::-webkit-search-results-button, .choices__input::-webkit-search-results-decoration {
  display: none;
}
.choices__input::-ms-clear, .choices__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

/* =====  End of Choices  ====== */
.choices__inner {
  border-radius: 0;
  background-color: #ffffff;
  border: none;
}

.choices__list--multiple .choices__item {
  background-color: #063462;
  border: none;
  border-radius: 0.1875rem;
  padding: 0.5em;
  font-size: revert;
  font-weight: 600;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #063462 !important;
  border: 0px;
}

.choices {
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
}

.input-group .divider {
  border-right: 1px solid #ddd;
  height: 100%;
  width: 100%;
}
.input-group:not(.border-0) .choices {
  border: 1px solid #ddd;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
.input-group .choices {
  -webkit-box-flex: 1;
          flex-grow: 1;
  margin-bottom: 0;
}
.input-group .choices .choices__inner {
  height: 100%;
  padding: 0.875rem;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}
.input-group .choices .choices__inner .choices__list {
  -webkit-box-ordinal-group: 3;
          order: 2;
}
.input-group .choices .choices__inner .choices__list:not(:empty) {
  margin-top: 0.3125rem;
}
.input-group .choices .choices__inner input[type=search] {
  -webkit-box-ordinal-group: 2;
          order: 1;
}
.input-group .choices .choices__inner .choices__button {
  background-size: 10px;
}
.input-group .choices input[type=search] {
  width: 100% !important;
  min-width: initial !important;
  -webkit-box-flex: 1;
          flex-grow: 1;
  padding: 0.375rem;
  padding-left: 0.4375rem;
  margin: 0;
  margin-bottom: 1px;
  background-position: left 0 center;
  text-indent: 1.5rem;
}
.input-group.input-group-lg .choices {
  padding-top: 0.25rem;
  padding-left: 0.25rem;
  background: #ffffff;
}
.input-group .choices__list[aria-expanded], .input-group .choices__list--dropdown {
  width: -webkit-calc(100% + 2px) !important;
  width: calc(100% + 2px) !important;
  margin-top: 0 !important;
  left: -1px !important;
  z-index: 1010 !important;
  min-width: 350px;
}

/* purgecss start ignore */
[data-namespace=review] [data-widget=one-to-ten] {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.375rem 0.75rem;
}
@media (max-width: 767.98px) {
  [data-namespace=review] [data-widget=one-to-ten] .break {
    flex-basis: 100%;
    display: block !important;
    height: 0;
  }
}
[data-namespace=review] [data-widget=one-to-ten] .break {
  display: none;
}
[data-namespace=review] [data-widget=one-to-ten] input[type=radio]:not(:checked) + label.btn-label {
  background: #063462;
  color: #ffffff;
}
[data-namespace=review] [data-widget=one-to-ten] input[type=radio]:checked + label.btn-label {
  border-color: #063462 !important;
  background: #063462 !important;
  color: #ffffff !important;
}
[data-namespace=review] [data-widget=one-to-ten] input[type=radio]:checked ~ label.btn-label {
  border-color: #063462;
  background: #ffffff;
  color: #063462;
}
[data-namespace=review] [data-widget=one-to-ten] input[type=radio] + label {
  width: 56px;
  min-height: 41px;
  line-height: 19px !important;
}
@media (max-width: 767.98px) {
  [data-namespace=review] [data-widget=one-to-ten] input[type=radio] + label {
    width: 48px;
  }
}
@media (min-width: 768px) {
  [data-namespace=review] [data-widget=one-to-ten] input[type=radio] + label.btn-label:hover {
    background: #3e6a91 !important;
    color: #ffffff !important;
  }
}
[data-namespace=review] [data-widget=three-choices] {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}
[data-namespace=review] [data-widget=three-choices] input[type=checkbox] + label {
  background: #ffffff;
  color: #063462;
  border-color: #063462 !important;
  min-height: 41px;
  line-height: 19px !important;
}
@media (max-width: 767.98px) {
  [data-namespace=review] [data-widget=three-choices] input[type=checkbox] + label {
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
}
[data-namespace=review] [data-widget=three-choices] input[type=checkbox]:checked + label.btn-label {
  background: #063462 !important;
  color: #ffffff !important;
}
[data-namespace=review] [data-widget=three-choices] input[type=checkbox]:checked + label.btn-label.has-errors {
  background: #ff3944 !important;
  border-color: #ff3944 !important;
}
@media (min-width: 768px) {
  [data-namespace=review] [data-widget=three-choices] input[type=checkbox] + label.btn-label:hover {
    background: #3e6a91 !important;
    color: #ffffff !important;
  }
}

/* purgecss end ignore */
.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  background: #ffffff;
  width: 100vw;
  z-index: 1036;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  overflow-x: hidden;
  overscroll-behavior: contain;
}
.side-panel.closed {
  display: none;
}
@media (min-width: 768px) {
  .side-panel {
    width: 28rem;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .side-panel.closed {
    right: -28rem;
  }
}
.side-panel .side-panel-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
.side-panel .onboarding-buttons {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}
.side-panel .onboarding-buttons .onboarding-button {
  height: 3em;
  background: white;
  color: #063462;
  width: 10rem;
  margin: auto;
  height: 9rem;
  text-align: center;
  font-size: 1rem;
  border: solid 2px #063462;
  border-radius: 0.5em;
  font-weight: 700;
}
.side-panel .onboarding-buttons .onboarding-button .icon {
  font-size: 2.5em;
}
.side-panel input.from-sso {
  border-color: #3e9187;
  background-color: #e8f7f5;
}
.side-panel label.from-sso {
  color: #3e9187;
}
.side-panel .side-panel-padding {
  padding: 2.5rem;
}
@media (min-width: 768px) {
  .side-panel .side-panel-padding:not(.has-logo) {
    padding-top: 5rem;
  }
}
@media (max-width: 768px) {
  .side-panel .side-panel-padding {
    padding: 2.5rem 1.25rem;
  }
}
.side-panel .side-panel-header {
  background-color: #e9f4fa;
}
@media (max-width: 767px) {
  .side-panel .side-panel-header {
    background-color: #063462;
    color: #ffffff;
  }
}
.side-panel .side-panel-footer {
  background-color: white;
  bottom: 0;
  z-index: 2;
  padding: 28px 20px;
}
.side-panel .side-panel-footer.side-panel-footer-adaptive {
  background: white;
  padding: 0;
}
.side-panel a.link {
  color: #83c5ff;
  font-weight: 700 !important;
  text-decoration: underline !important;
  font-size: 1rem;
}
.side-panel .login-panel-logo {
  border-radius: 50%;
  box-shadow: 2px 2px 5px #cdd6e0;
  padding: 20px;
}
.side-panel .login-panel-logo img,
.side-panel .login-panel-logo svg {
  position: relative;
  top: 2px;
}
.side-panel .login-panel-title {
  margin-top: 48px;
  margin-bottom: 48px;
}
.side-panel .login-panel-connect-buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
}
.side-panel .login-panel-connect-buttons > *:not(:last-child) {
  margin-bottom: 1rem;
}
.side-panel .nav-tabs {
  border: 0;
}
.side-panel .nav-tabs .nav-link.inactive {
  color: #a7afc3;
  text-decoration: none;
}
.side-panel .nav-tabs .nav-link.active {
  box-shadow: -1px -6px 6px 0px rgba(0, 0, 0, 0.0392156863);
  color: #063462;
}
.side-panel .nav-tabs .nav-link.active span {
  border-bottom: 2px solid;
}
.side-panel .stepper .step {
  color: #a7afc3;
}
.side-panel .stepper .step-bar {
  position: relative;
}
.side-panel .stepper .step-bar .dot {
  background: white;
  height: 0.25em;
  width: 0.25em;
  position: absolute;
  top: 0.125em;
  right: 0.125em;
  border-radius: 0.125em;
}
.side-panel a.logout-link {
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 40px;
  color: #83c5ff;
}
.side-panel a.logout-link span {
  text-decoration: underline !important;
  padding: 0 5px;
}

@media (min-width: 768px) {
  .side-panel.side-panel-mobile {
    all: revert;
  }
}
.side-panel-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.8;
  z-index: 1035;
}
.side-panel-backdrop.closed {
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 767px) {
  .side-panel-backdrop {
    display: none;
  }
}

@media (min-width: 768px) {
  .side-panel-backdrop.side-panel-mobile {
    all: revert;
  }
}
.strikethrough-like-title {
  color: #063462;
  display: inline-block;
  position: relative;
  padding: 0 20px;
}
.strikethrough-like-title:before, .strikethrough-like-title:after {
  height: 2px;
  background-color: #063462;
  position: absolute;
  z-index: 1;
  content: "";
  width: 100%;
  top: 0;
  -webkit-transform: translateY(11px);
          transform: translateY(11px);
}
.strikethrough-like-title > span {
  position: relative;
  z-index: 2;
  padding: 0 20px;
  white-space: nowrap;
  background: white;
}
.strikethrough-like-title:before {
  left: 0;
}
.strikethrough-like-title:after {
  right: 0;
}

.logo-infoconnect path {
  fill: #29354e;
  -webkit-transition: fill 0.15s ease-in-out;
  transition: fill 0.15s ease-in-out;
}

button:hover .logo-infoconnect path,
a:hover .logo-infoconnect path {
  fill: #ffffff;
}

:root {
  --version: 1.39;
}

@media (max-width: 991.98px) {
  .overflow-hidden-mobile {
    overflow: hidden;
  }
}

.messenger-block-container {
  height: 100%;
  width: 100%;
}
.messenger-block-container .message-form {
  overflow: auto;
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 992px) {
  .messenger-block-container .message-form {
    position: static;
    height: 100%;
  }
}
.messenger-block-container .close-message-form {
  font-size: 2rem;
  line-height: 1rem;
}
.messenger-block-container .dates .date {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.messenger-block-container .dates .date .dp__input {
  font-size: 0.96em;
  padding: 0.25em 0.5em;
}
.messenger-block-container .dates .date .dp__input.dp__input_icon_pad {
  padding-left: 2em;
}
.messenger-block-container .dates .date .dp__input_icons {
  padding: 0.25em 0.5em;
}
.messenger-block-container .input-phone.is-invalid {
  border-color: #ff3944;
}

#messenger_container {
  padding-bottom: 0 !important;
  -webkit-box-flex: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.messenger-container {
  -webkit-box-flex: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
.messenger-container .messenger-layout {
  -webkit-box-flex: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
}
@media (max-width: 1279.98px) {
  .messenger-container .messenger-layout .column {
    width: 100%;
  }
  .messenger-container .messenger-layout .hidden-mobile {
    display: none;
  }
}
@media (min-width: 1280px) {
  .messenger-container .messenger-layout .left-column,
  .messenger-container .messenger-layout .right-column {
    max-width: 313px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .messenger-container .messenger-layout .left-column.hidden,
  .messenger-container .messenger-layout .right-column.hidden {
    min-width: 0;
    max-width: 0;
    visibility: hidden;
    display: block !important;
  }
  .messenger-container .messenger-layout .left-column {
    border-right: solid 1px #063462;
  }
  .messenger-container .messenger-layout .right-column {
    border-left: solid 1px #063462;
  }
  .messenger-container .messenger-layout .center-column {
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
}
.messenger-container .nav-tabs .nav-item.active .tab-label {
  -webkit-text-decoration: underline 2px #83c5ff;
          text-decoration: underline 2px #83c5ff;
}
.messenger-container .conversations-list {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex-grow: 1;
}
@media (min-width: 1280px) {
  .messenger-container .conversations-list {
    padding: 1em 0;
  }
}
.messenger-container .conversations-list .conversation-item {
  cursor: pointer;
  text-decoration: none !important;
  color: #a7afc3;
}
.messenger-container .conversations-list .conversation-item.has-unread-messages {
  color: #063462;
}
.messenger-container .boats-list {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex-grow: 1;
}
@media (min-width: 1280px) {
  .messenger-container .boats-list {
    padding: 1em 0;
  }
}
.messenger-container .boats-list .boat-item .new-messages {
  color: #ff3944;
}
.messenger-container .boat-icon {
  margin-right: 1rem;
  min-width: 3rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.messenger-container .boat-icon.boat-icon-right {
  margin-left: 1rem;
  margin-right: 0;
}
.messenger-container .boat-thumbnail {
  margin-right: 1rem;
  height: 3.25em;
  width: 3.25em;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.messenger-container .user-pp-container .user-pp {
  min-width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.messenger-container .user-pp-container div.user-pp {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  color: white;
  -webkit-box-align: center;
          align-items: center;
}
.messenger-container .tag {
  padding: 0 0.25em;
}
.messenger-container .tag.tag-new {
  color: #ffffff;
  background-color: #ff3944;
}
.messenger-container .tag.tag-confirmed {
  color: #ffffff;
  background-color: #3e9187;
}
.messenger-container .tag.tag-unconfirmed, .messenger-container .tag.tag-hidden {
  color: #ffffff;
  background-color: #ff3944;
}
.messenger-container .messenger-home {
  height: 100%;
}
@media (max-width: 991.98px) {
  .messenger-container .messenger-home {
    height: 100vh;
  }
}
.messenger-container .messenger-conversation-container {
  background-color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  height: 100%;
}
@media (max-width: 991.98px) {
  .messenger-container .messenger-conversation-container {
    z-index: 1002;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.messenger-container .messages-list {
  overflow: auto;
  overscroll-behavior: contain;
  padding: 1em;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex-grow: 1;
  flex-basis: 1px;
}
.messenger-container .messages-list .message-marker {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  margin: 1em;
  -webkit-box-align: center;
          align-items: center;
  scroll-margin-top: 5rem;
}
.messenger-container .messages-list .message-marker.today-message-marker {
  color: #a7afc3;
}
.messenger-container .messages-list .message-marker.unread-message-marker {
  color: #ff3944;
}
.messenger-container .messages-list .message-marker .message-marker-line {
  -webkit-box-flex: 1;
          flex-grow: 1;
  border-top: solid 2px;
  font-size: 0.5em;
}
.messenger-container .messages-list .message-marker .message-marker-text {
  font-weight: bold;
  margin: 0 1em;
}
.messenger-container .messages-list .message-item.auto {
  background: #fef9f5;
  border-radius: 0.5em;
  border: solid 2px #edeef2;
}
.messenger-container .messages-list .message-item.auto a {
  font-weight: bold;
  text-decoration: underline;
}
.messenger-container .messages-list .message-item .message-attachments {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  flex-wrap: wrap;
}
.messenger-container .messages-list .message-item .message-attachments .file {
  margin: 0.5em;
  position: relative;
  cursor: pointer;
}
.messenger-container .messages-list .message-item .message-attachments .file.more-files {
  width: 100px;
  background-size: cover;
}
.messenger-container .messages-list .message-item .message-attachments .file.more-files span {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-size: 4em;
  font-weight: bold;
  display: inline-block;
  line-height: 100px;
  text-align: center;
  cursor: pointer;
}
.messenger-container .messages-list .message-item .message-attachments .file img {
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}
.messenger-container .attachments-detail {
  position: fixed;
  overscroll-behavior: contain;
  background: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  z-index: 1036;
}
@media (max-width: 991.98px) {
  .messenger-container .attachments-detail {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
}
@media (min-width: 992px) {
  .messenger-container .attachments-detail {
    top: 50%;
    left: 50%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
}
.messenger-container .attachments-detail .attachments-list {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding: 1em;
  overflow: auto;
  -webkit-box-align: start;
          align-items: flex-start;
}
@media (min-width: 992px) {
  .messenger-container .attachments-detail .attachments-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
  }
}
.messenger-container .attachments-detail .attachments-list .image-container {
  margin: 1em 0;
  width: min(600px, 100%);
  color: #a7afc3;
}
.messenger-container .attachments-detail .attachments-list .image-container img.image {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 992px) {
  .messenger-container .attachments-detail .attachments-list .image-container img.image {
    width: 180px;
    height: 180px;
  }
}
.messenger-container .attachments-detail .download-banner {
  position: -webkit-sticky;
  position: sticky;
  background: white;
  padding: 0.5em;
  text-align: center;
  bottom: 0;
}
.messenger-container .attachments-detail .close-button {
  padding: 0.25rem;
  font-size: 3em;
  cursor: pointer;
  line-height: 1em;
}
.messenger-container .attachments-detail-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.8;
  z-index: 1035;
}
.messenger-container .attachments-detail-backdrop.closed {
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1279.98px) {
  .messenger-container .attachments-detail-backdrop {
    display: none;
  }
}
.messenger-container .interlocutor-banner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  padding: 1rem;
  z-index: 1;
}
.messenger-container .interlocutor-banner .back-button {
  font-size: 2rem;
  cursor: pointer;
}
@media (min-width: 1280px) {
  .messenger-container .interlocutor-banner .back-button {
    display: none;
  }
}
.messenger-container .interlocutor-banner .user-info {
  -webkit-box-flex: 1;
          flex-grow: 1;
  padding: 0 0.5em;
}
.messenger-container .interlocutor-banner .details {
  font-size: 2rem;
  cursor: pointer;
}
.messenger-container .phone-button {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  margin: 0 auto;
}
.messenger-container .bottom-banner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  box-shadow: 0 -0.5rem 1rem rgba(37, 53, 67, 0.15);
}
.messenger-container .bottom-banner .message-banner {
  position: relative;
  padding: 0.5em;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}
.messenger-container .bottom-banner .message-banner .message-input {
  -webkit-box-flex: 1;
          flex-grow: 1;
  margin: 0 1em;
}
.messenger-container .bottom-banner .message-banner .send-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.messenger-container .interlocutor-info {
  background: white;
  height: 100%;
}
.messenger-container .interlocutor-info .interlocutor-info-content {
  flex-basis: 1px;
  -webkit-box-flex: 1;
          flex-grow: 1;
  overflow: auto;
}
.messenger-container .interlocutor-info .interlocutor-data-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}
.messenger-container .interlocutor-info .interlocutor-data-list dt {
  font-weight: normal;
}
.messenger-container .interlocutor-info .interlocutor-data-list dd {
  font-weight: bold;
  margin: 0;
}
@media (max-width: 1279.98px) {
  .messenger-container .interlocutor-info {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    z-index: 1002;
  }
}
.messenger-container .files-to-upload {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  flex-wrap: wrap;
  background-color: #ffffff;
}
.messenger-container .files-to-upload .file {
  margin: 0.5em;
  position: relative;
}
.messenger-container .files-to-upload .file img {
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}
.messenger-container .files-to-upload .file .file-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  line-height: 1em;
}
.messenger-container .files-to-upload .file .file-close:hover {
  background: rgba(0, 0, 0, 0.8);
}

.alert-floating {
  position: absolute;
  top: -4em;
  width: 90%;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.alert-floating.hide {
  opacity: 0;
  visibility: hidden;
}

.filters {
  height: 100%;
}

.fixed-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 100vh;
  width: 100vw;
  z-index: 1050;
}

.empty-panel-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  height: 100%;
}
.empty-panel-container .empty-panel-content {
  text-align: center;
}
.empty-panel-container .empty-panel-content .bi-cursor-fill {
  font-size: 2rem;
}
.empty-panel-container .empty-panel-content .bi-envelope-open {
  font-size: 6rem;
}

.drawer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
.drawer .drawer-title {
  text-align: center;
  color: #a7afc3;
  text-decoration: underline;
}
.drawer .drawer-content {
  overflow: auto;
  -webkit-transition: max-height 0.3s;
  transition: max-height 0.3s;
  -webkit-box-flex: 1;
          flex-grow: 1;
  flex-basis: 1px;
}
.drawer.open {
  -webkit-box-flex: 1;
          flex-grow: 1;
}
.drawer.close .drawer-title {
  cursor: pointer;
}
.drawer.close .drawer-content {
  display: none;
}

table > :not(caption) > *:last-child > * {
  border-bottom-width: 0;
}

.fib, .fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fi {
  position: relative;
  display: inline-block;
  width: 1.333333em;
  line-height: 1em;
}
.fi:before {
  content: " ";
}
.fi.fis {
  width: 1em;
}

.fi-xx {
  background-image: url(https://static.bandofboats.com/prod/build/images/xx.96f901b4.svg);
}
.fi-xx.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/xx.aa8f6670.svg);
}

.fi-ad {
  background-image: url(https://static.bandofboats.com/prod/build/images/ad.5a5f3d81.svg);
}
.fi-ad.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ad.4578dd00.svg);
}

.fi-ae {
  background-image: url(https://static.bandofboats.com/prod/build/images/ae.25074606.svg);
}
.fi-ae.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ae.e3396fd0.svg);
}

.fi-af {
  background-image: url(https://static.bandofboats.com/prod/build/images/af.cd5bccc3.svg);
}
.fi-af.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/af.53c1a623.svg);
}

.fi-ag {
  background-image: url(https://static.bandofboats.com/prod/build/images/ag.4852b5c9.svg);
}
.fi-ag.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ag.677e23c7.svg);
}

.fi-ai {
  background-image: url(https://static.bandofboats.com/prod/build/images/ai.e30aaaa5.svg);
}
.fi-ai.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ai.769d0f8a.svg);
}

.fi-al {
  background-image: url(https://static.bandofboats.com/prod/build/images/al.39bea1bb.svg);
}
.fi-al.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/al.7c441ab1.svg);
}

.fi-am {
  background-image: url(https://static.bandofboats.com/prod/build/images/am.683c20ed.svg);
}
.fi-am.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/am.eaae878c.svg);
}

.fi-ao {
  background-image: url(https://static.bandofboats.com/prod/build/images/ao.61f30477.svg);
}
.fi-ao.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ao.5f3847a7.svg);
}

.fi-aq {
  background-image: url(https://static.bandofboats.com/prod/build/images/aq.2405e38d.svg);
}
.fi-aq.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/aq.0aff28fa.svg);
}

.fi-ar {
  background-image: url(https://static.bandofboats.com/prod/build/images/ar.a020c650.svg);
}
.fi-ar.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ar.a6fa6ec4.svg);
}

.fi-as {
  background-image: url(https://static.bandofboats.com/prod/build/images/as.c7e4903a.svg);
}
.fi-as.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/as.294ef8bf.svg);
}

.fi-at {
  background-image: url(https://static.bandofboats.com/prod/build/images/at.b511d36e.svg);
}
.fi-at.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/at.5ffa25ce.svg);
}

.fi-au {
  background-image: url(https://static.bandofboats.com/prod/build/images/au.8cc94330.svg);
}
.fi-au.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/au.97435dd8.svg);
}

.fi-aw {
  background-image: url(https://static.bandofboats.com/prod/build/images/aw.6c82c52f.svg);
}
.fi-aw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/aw.a4cfc016.svg);
}

.fi-ax {
  background-image: url(https://static.bandofboats.com/prod/build/images/ax.dd048088.svg);
}
.fi-ax.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ax.3882371f.svg);
}

.fi-az {
  background-image: url(https://static.bandofboats.com/prod/build/images/az.ffc79bfa.svg);
}
.fi-az.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/az.acf7a85a.svg);
}

.fi-ba {
  background-image: url(https://static.bandofboats.com/prod/build/images/ba.64e381d4.svg);
}
.fi-ba.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ba.2d7f430f.svg);
}

.fi-bb {
  background-image: url(https://static.bandofboats.com/prod/build/images/bb.333ab7bf.svg);
}
.fi-bb.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bb.4b64dc6c.svg);
}

.fi-bd {
  background-image: url(https://static.bandofboats.com/prod/build/images/bd.0ad8c443.svg);
}
.fi-bd.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bd.a62e330d.svg);
}

.fi-be {
  background-image: url(https://static.bandofboats.com/prod/build/images/be.e9ec217e.svg);
}
.fi-be.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/be.fb381534.svg);
}

.fi-bf {
  background-image: url(https://static.bandofboats.com/prod/build/images/bf.a3957b0a.svg);
}
.fi-bf.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bf.bb616c9e.svg);
}

.fi-bg {
  background-image: url(https://static.bandofboats.com/prod/build/images/bg.9c87ba43.svg);
}
.fi-bg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bg.0717abbc.svg);
}

.fi-bh {
  background-image: url(https://static.bandofboats.com/prod/build/images/bh.3310879d.svg);
}
.fi-bh.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bh.d6e3bed5.svg);
}

.fi-bi {
  background-image: url(https://static.bandofboats.com/prod/build/images/bi.5d343510.svg);
}
.fi-bi.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bi.69b06700.svg);
}

.fi-bj {
  background-image: url(https://static.bandofboats.com/prod/build/images/bj.b04c72b6.svg);
}
.fi-bj.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bj.c1bc198c.svg);
}

.fi-bl {
  background-image: url(https://static.bandofboats.com/prod/build/images/bl.67c5ef35.svg);
}
.fi-bl.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bl.66cdc56c.svg);
}

.fi-bm {
  background-image: url(https://static.bandofboats.com/prod/build/images/bm.b04bb306.svg);
}
.fi-bm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bm.6ea13994.svg);
}

.fi-bn {
  background-image: url(https://static.bandofboats.com/prod/build/images/bn.43328fe6.svg);
}
.fi-bn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bn.3edc674d.svg);
}

.fi-bo {
  background-image: url(https://static.bandofboats.com/prod/build/images/bo.52e1cc3d.svg);
}
.fi-bo.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bo.94c00696.svg);
}

.fi-bq {
  background-image: url(https://static.bandofboats.com/prod/build/images/bq.04ca6698.svg);
}
.fi-bq.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bq.1698f288.svg);
}

.fi-br {
  background-image: url(https://static.bandofboats.com/prod/build/images/br.6b2fd790.svg);
}
.fi-br.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/br.cb4af561.svg);
}

.fi-bs {
  background-image: url(https://static.bandofboats.com/prod/build/images/bs.056e551c.svg);
}
.fi-bs.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bs.7dbd1c16.svg);
}

.fi-bt {
  background-image: url(https://static.bandofboats.com/prod/build/images/bt.8037950f.svg);
}
.fi-bt.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bt.fe5e31ef.svg);
}

.fi-bv {
  background-image: url(https://static.bandofboats.com/prod/build/images/bv.3d8e6f49.svg);
}
.fi-bv.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bv.976c853a.svg);
}

.fi-bw {
  background-image: url(https://static.bandofboats.com/prod/build/images/bw.286ba8fe.svg);
}
.fi-bw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bw.b6a321af.svg);
}

.fi-by {
  background-image: url(https://static.bandofboats.com/prod/build/images/by.4c57e6cb.svg);
}
.fi-by.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/by.0bc23418.svg);
}

.fi-bz {
  background-image: url(https://static.bandofboats.com/prod/build/images/bz.224e3b4d.svg);
}
.fi-bz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/bz.ad6f06f4.svg);
}

.fi-ca {
  background-image: url(https://static.bandofboats.com/prod/build/images/ca.2ef1694a.svg);
}
.fi-ca.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ca.27f31b05.svg);
}

.fi-cc {
  background-image: url(https://static.bandofboats.com/prod/build/images/cc.1e4d4d1f.svg);
}
.fi-cc.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cc.1e40e3de.svg);
}

.fi-cd {
  background-image: url(https://static.bandofboats.com/prod/build/images/cd.80689945.svg);
}
.fi-cd.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cd.588b1447.svg);
}

.fi-cf {
  background-image: url(https://static.bandofboats.com/prod/build/images/cf.a3843e79.svg);
}
.fi-cf.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cf.e2e89fb8.svg);
}

.fi-cg {
  background-image: url(https://static.bandofboats.com/prod/build/images/cg.ef7269ec.svg);
}
.fi-cg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cg.b92309a2.svg);
}

.fi-ch {
  background-image: url(https://static.bandofboats.com/prod/build/images/ch.55323a06.svg);
}
.fi-ch.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ch.e6b520ab.svg);
}

.fi-ci {
  background-image: url(https://static.bandofboats.com/prod/build/images/ci.c7146bc3.svg);
}
.fi-ci.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ci.133adff8.svg);
}

.fi-ck {
  background-image: url(https://static.bandofboats.com/prod/build/images/ck.6f73c68e.svg);
}
.fi-ck.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ck.3e6dd6dd.svg);
}

.fi-cl {
  background-image: url(https://static.bandofboats.com/prod/build/images/cl.3ef2be87.svg);
}
.fi-cl.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cl.178b4ca5.svg);
}

.fi-cm {
  background-image: url(https://static.bandofboats.com/prod/build/images/cm.7eff011c.svg);
}
.fi-cm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cm.c5fdb172.svg);
}

.fi-cn {
  background-image: url(https://static.bandofboats.com/prod/build/images/cn.e4f6436a.svg);
}
.fi-cn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cn.2e5f836f.svg);
}

.fi-co {
  background-image: url(https://static.bandofboats.com/prod/build/images/co.2035da4e.svg);
}
.fi-co.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/co.650be7bf.svg);
}

.fi-cr {
  background-image: url(https://static.bandofboats.com/prod/build/images/cr.da71ea0e.svg);
}
.fi-cr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cr.96cca329.svg);
}

.fi-cu {
  background-image: url(https://static.bandofboats.com/prod/build/images/cu.e26b31c7.svg);
}
.fi-cu.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cu.b6406c65.svg);
}

.fi-cv {
  background-image: url(https://static.bandofboats.com/prod/build/images/cv.aa02126c.svg);
}
.fi-cv.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cv.c4df14ee.svg);
}

.fi-cw {
  background-image: url(https://static.bandofboats.com/prod/build/images/cw.7b833291.svg);
}
.fi-cw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cw.023cc068.svg);
}

.fi-cx {
  background-image: url(https://static.bandofboats.com/prod/build/images/cx.2da85ddc.svg);
}
.fi-cx.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cx.897c7c5d.svg);
}

.fi-cy {
  background-image: url(https://static.bandofboats.com/prod/build/images/cy.385433a8.svg);
}
.fi-cy.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cy.20269f5c.svg);
}

.fi-cz {
  background-image: url(https://static.bandofboats.com/prod/build/images/cz.5a24757d.svg);
}
.fi-cz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cz.f7d5fa02.svg);
}

.fi-de {
  background-image: url(https://static.bandofboats.com/prod/build/images/de.89dca9a0.svg);
}
.fi-de.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/de.5b5148f3.svg);
}

.fi-dj {
  background-image: url(https://static.bandofboats.com/prod/build/images/dj.da9d5bb3.svg);
}
.fi-dj.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/dj.9d6f9c1f.svg);
}

.fi-dk {
  background-image: url(https://static.bandofboats.com/prod/build/images/dk.025c1210.svg);
}
.fi-dk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/dk.90f7d120.svg);
}

.fi-dm {
  background-image: url(https://static.bandofboats.com/prod/build/images/dm.a87ef307.svg);
}
.fi-dm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/dm.195779bb.svg);
}

.fi-do {
  background-image: url(https://static.bandofboats.com/prod/build/images/do.5cd644ee.svg);
}
.fi-do.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/do.30274580.svg);
}

.fi-dz {
  background-image: url(https://static.bandofboats.com/prod/build/images/dz.0f087582.svg);
}
.fi-dz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/dz.67624652.svg);
}

.fi-ec {
  background-image: url(https://static.bandofboats.com/prod/build/images/ec.0640a50e.svg);
}
.fi-ec.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ec.3cd23382.svg);
}

.fi-ee {
  background-image: url(https://static.bandofboats.com/prod/build/images/ee.e2efe36d.svg);
}
.fi-ee.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ee.7a473bb4.svg);
}

.fi-eg {
  background-image: url(https://static.bandofboats.com/prod/build/images/eg.97e2c2ad.svg);
}
.fi-eg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/eg.f636d501.svg);
}

.fi-eh {
  background-image: url(https://static.bandofboats.com/prod/build/images/eh.a2b2a954.svg);
}
.fi-eh.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/eh.8941dc19.svg);
}

.fi-er {
  background-image: url(https://static.bandofboats.com/prod/build/images/er.a45d00f8.svg);
}
.fi-er.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/er.5c497a49.svg);
}

.fi-es {
  background-image: url(https://static.bandofboats.com/prod/build/images/es.49925d3c.svg);
}
.fi-es.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/es.37c5897b.svg);
}

.fi-et {
  background-image: url(https://static.bandofboats.com/prod/build/images/et.d27d9d1a.svg);
}
.fi-et.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/et.da75d56f.svg);
}

.fi-fi {
  background-image: url(https://static.bandofboats.com/prod/build/images/fi.ead68a19.svg);
}
.fi-fi.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/fi.aed885cc.svg);
}

.fi-fj {
  background-image: url(https://static.bandofboats.com/prod/build/images/fj.b0c145e1.svg);
}
.fi-fj.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/fj.e44c1e15.svg);
}

.fi-fk {
  background-image: url(https://static.bandofboats.com/prod/build/images/fk.0d7b8bda.svg);
}
.fi-fk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/fk.2c0c2e5b.svg);
}

.fi-fm {
  background-image: url(https://static.bandofboats.com/prod/build/images/fm.18f7e089.svg);
}
.fi-fm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/fm.9b826793.svg);
}

.fi-fo {
  background-image: url(https://static.bandofboats.com/prod/build/images/fo.3165eadc.svg);
}
.fi-fo.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/fo.54fd5278.svg);
}

.fi-fr {
  background-image: url(https://static.bandofboats.com/prod/build/images/fr.0313c7ea.svg);
}
.fi-fr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/fr.9e329b0f.svg);
}

.fi-ga {
  background-image: url(https://static.bandofboats.com/prod/build/images/ga.ccf7eecb.svg);
}
.fi-ga.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ga.d33bfe61.svg);
}

.fi-gb {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb.84d4bb24.svg);
}
.fi-gb.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb.6cf90268.svg);
}

.fi-gd {
  background-image: url(https://static.bandofboats.com/prod/build/images/gd.701c23bc.svg);
}
.fi-gd.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gd.d96dfdc2.svg);
}

.fi-ge {
  background-image: url(https://static.bandofboats.com/prod/build/images/ge.3c65f52f.svg);
}
.fi-ge.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ge.4fc95ed0.svg);
}

.fi-gf {
  background-image: url(https://static.bandofboats.com/prod/build/images/gf.16328213.svg);
}
.fi-gf.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gf.5fc814b1.svg);
}

.fi-gg {
  background-image: url(https://static.bandofboats.com/prod/build/images/gg.b8779f18.svg);
}
.fi-gg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gg.cff3c621.svg);
}

.fi-gh {
  background-image: url(https://static.bandofboats.com/prod/build/images/gh.87686ea0.svg);
}
.fi-gh.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gh.e2793fb1.svg);
}

.fi-gi {
  background-image: url(https://static.bandofboats.com/prod/build/images/gi.b4cbdc45.svg);
}
.fi-gi.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gi.1e5fb2e2.svg);
}

.fi-gl {
  background-image: url(https://static.bandofboats.com/prod/build/images/gl.633d308e.svg);
}
.fi-gl.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gl.2466f7b0.svg);
}

.fi-gm {
  background-image: url(https://static.bandofboats.com/prod/build/images/gm.6e4caca4.svg);
}
.fi-gm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gm.dfd25b88.svg);
}

.fi-gn {
  background-image: url(https://static.bandofboats.com/prod/build/images/gn.65edeaef.svg);
}
.fi-gn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gn.347b60cf.svg);
}

.fi-gp {
  background-image: url(https://static.bandofboats.com/prod/build/images/gp.ca0b8d6f.svg);
}
.fi-gp.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gp.34687ce5.svg);
}

.fi-gq {
  background-image: url(https://static.bandofboats.com/prod/build/images/gq.ef404347.svg);
}
.fi-gq.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gq.fa323851.svg);
}

.fi-gr {
  background-image: url(https://static.bandofboats.com/prod/build/images/gr.969458b7.svg);
}
.fi-gr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gr.f4f1efd0.svg);
}

.fi-gs {
  background-image: url(https://static.bandofboats.com/prod/build/images/gs.a193265c.svg);
}
.fi-gs.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gs.10c718e0.svg);
}

.fi-gt {
  background-image: url(https://static.bandofboats.com/prod/build/images/gt.d15a032e.svg);
}
.fi-gt.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gt.b0b9dc32.svg);
}

.fi-gu {
  background-image: url(https://static.bandofboats.com/prod/build/images/gu.72d01417.svg);
}
.fi-gu.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gu.afcad4d4.svg);
}

.fi-gw {
  background-image: url(https://static.bandofboats.com/prod/build/images/gw.3b1eba4b.svg);
}
.fi-gw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gw.2bfb1be1.svg);
}

.fi-gy {
  background-image: url(https://static.bandofboats.com/prod/build/images/gy.f3e2f1ac.svg);
}
.fi-gy.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gy.0cb6e816.svg);
}

.fi-hk {
  background-image: url(https://static.bandofboats.com/prod/build/images/hk.8b89a0a3.svg);
}
.fi-hk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/hk.b80f4f84.svg);
}

.fi-hm {
  background-image: url(https://static.bandofboats.com/prod/build/images/hm.d7f4f620.svg);
}
.fi-hm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/hm.fe75e73a.svg);
}

.fi-hn {
  background-image: url(https://static.bandofboats.com/prod/build/images/hn.2427006a.svg);
}
.fi-hn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/hn.084f590f.svg);
}

.fi-hr {
  background-image: url(https://static.bandofboats.com/prod/build/images/hr.f1fedc59.svg);
}
.fi-hr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/hr.4bce8db1.svg);
}

.fi-ht {
  background-image: url(https://static.bandofboats.com/prod/build/images/ht.9903be3b.svg);
}
.fi-ht.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ht.9663eed4.svg);
}

.fi-hu {
  background-image: url(https://static.bandofboats.com/prod/build/images/hu.e404d718.svg);
}
.fi-hu.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/hu.cb329e88.svg);
}

.fi-id {
  background-image: url(https://static.bandofboats.com/prod/build/images/id.f624e2c8.svg);
}
.fi-id.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/id.578a8a9c.svg);
}

.fi-ie {
  background-image: url(https://static.bandofboats.com/prod/build/images/ie.0542b946.svg);
}
.fi-ie.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ie.9e3414e8.svg);
}

.fi-il {
  background-image: url(https://static.bandofboats.com/prod/build/images/il.1e6c2f0e.svg);
}
.fi-il.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/il.d92f72de.svg);
}

.fi-im {
  background-image: url(https://static.bandofboats.com/prod/build/images/im.ef5d541a.svg);
}
.fi-im.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/im.00dd8b7c.svg);
}

.fi-in {
  background-image: url(https://static.bandofboats.com/prod/build/images/in.12c66a16.svg);
}
.fi-in.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/in.5d3291b5.svg);
}

.fi-io {
  background-image: url(https://static.bandofboats.com/prod/build/images/io.a5cb8dd8.svg);
}
.fi-io.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/io.5237a55f.svg);
}

.fi-iq {
  background-image: url(https://static.bandofboats.com/prod/build/images/iq.f3ea19bd.svg);
}
.fi-iq.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/iq.acde3320.svg);
}

.fi-ir {
  background-image: url(https://static.bandofboats.com/prod/build/images/ir.c14862b9.svg);
}
.fi-ir.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ir.11a5a321.svg);
}

.fi-is {
  background-image: url(https://static.bandofboats.com/prod/build/images/is.8f886194.svg);
}
.fi-is.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/is.8bc19a21.svg);
}

.fi-it {
  background-image: url(https://static.bandofboats.com/prod/build/images/it.08db2cd1.svg);
}
.fi-it.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/it.febcc1b1.svg);
}

.fi-je {
  background-image: url(https://static.bandofboats.com/prod/build/images/je.4e2fc01b.svg);
}
.fi-je.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/je.2215c3a0.svg);
}

.fi-jm {
  background-image: url(https://static.bandofboats.com/prod/build/images/jm.46c20a3e.svg);
}
.fi-jm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/jm.593d0ceb.svg);
}

.fi-jo {
  background-image: url(https://static.bandofboats.com/prod/build/images/jo.84446d30.svg);
}
.fi-jo.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/jo.5e426c88.svg);
}

.fi-jp {
  background-image: url(https://static.bandofboats.com/prod/build/images/jp.d288d548.svg);
}
.fi-jp.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/jp.5d4d1d1e.svg);
}

.fi-ke {
  background-image: url(https://static.bandofboats.com/prod/build/images/ke.d6258289.svg);
}
.fi-ke.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ke.2b032c4b.svg);
}

.fi-kg {
  background-image: url(https://static.bandofboats.com/prod/build/images/kg.d228cc16.svg);
}
.fi-kg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/kg.b4bef1b0.svg);
}

.fi-kh {
  background-image: url(https://static.bandofboats.com/prod/build/images/kh.ffda5c64.svg);
}
.fi-kh.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/kh.246b79f0.svg);
}

.fi-ki {
  background-image: url(https://static.bandofboats.com/prod/build/images/ki.4aad53ac.svg);
}
.fi-ki.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ki.97c26680.svg);
}

.fi-km {
  background-image: url(https://static.bandofboats.com/prod/build/images/km.e4580994.svg);
}
.fi-km.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/km.ef8a6075.svg);
}

.fi-kn {
  background-image: url(https://static.bandofboats.com/prod/build/images/kn.650b9279.svg);
}
.fi-kn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/kn.84087585.svg);
}

.fi-kp {
  background-image: url(https://static.bandofboats.com/prod/build/images/kp.1bcf41f7.svg);
}
.fi-kp.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/kp.8da1021b.svg);
}

.fi-kr {
  background-image: url(https://static.bandofboats.com/prod/build/images/kr.cb529202.svg);
}
.fi-kr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/kr.977537ff.svg);
}

.fi-kw {
  background-image: url(https://static.bandofboats.com/prod/build/images/kw.d8ca915f.svg);
}
.fi-kw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/kw.969be875.svg);
}

.fi-ky {
  background-image: url(https://static.bandofboats.com/prod/build/images/ky.89bdd0e8.svg);
}
.fi-ky.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ky.cc8abed7.svg);
}

.fi-kz {
  background-image: url(https://static.bandofboats.com/prod/build/images/kz.4d1d995b.svg);
}
.fi-kz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/kz.1266e111.svg);
}

.fi-la {
  background-image: url(https://static.bandofboats.com/prod/build/images/la.67f27403.svg);
}
.fi-la.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/la.d7702465.svg);
}

.fi-lb {
  background-image: url(https://static.bandofboats.com/prod/build/images/lb.542b007b.svg);
}
.fi-lb.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/lb.ea6c1b91.svg);
}

.fi-lc {
  background-image: url(https://static.bandofboats.com/prod/build/images/lc.a5f7f206.svg);
}
.fi-lc.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/lc.1ef20b6d.svg);
}

.fi-li {
  background-image: url(https://static.bandofboats.com/prod/build/images/li.336f4357.svg);
}
.fi-li.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/li.834b1725.svg);
}

.fi-lk {
  background-image: url(https://static.bandofboats.com/prod/build/images/lk.853b4c53.svg);
}
.fi-lk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/lk.9ebbcf49.svg);
}

.fi-lr {
  background-image: url(https://static.bandofboats.com/prod/build/images/lr.61e7a998.svg);
}
.fi-lr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/lr.6af0f7ec.svg);
}

.fi-ls {
  background-image: url(https://static.bandofboats.com/prod/build/images/ls.4856aea2.svg);
}
.fi-ls.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ls.2ac94099.svg);
}

.fi-lt {
  background-image: url(https://static.bandofboats.com/prod/build/images/lt.93a07daf.svg);
}
.fi-lt.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/lt.1d07a8b8.svg);
}

.fi-lu {
  background-image: url(https://static.bandofboats.com/prod/build/images/lu.c155a684.svg);
}
.fi-lu.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/lu.1de57f2a.svg);
}

.fi-lv {
  background-image: url(https://static.bandofboats.com/prod/build/images/lv.ff6176a6.svg);
}
.fi-lv.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/lv.884e7f97.svg);
}

.fi-ly {
  background-image: url(https://static.bandofboats.com/prod/build/images/ly.9c0afe9e.svg);
}
.fi-ly.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ly.c0460253.svg);
}

.fi-ma {
  background-image: url(https://static.bandofboats.com/prod/build/images/ma.ab052b41.svg);
}
.fi-ma.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ma.4d3a6f5c.svg);
}

.fi-mc {
  background-image: url(https://static.bandofboats.com/prod/build/images/mc.b5edb755.svg);
}
.fi-mc.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mc.21f385d1.svg);
}

.fi-md {
  background-image: url(https://static.bandofboats.com/prod/build/images/md.a60006c4.svg);
}
.fi-md.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/md.7c8cc80b.svg);
}

.fi-me {
  background-image: url(https://static.bandofboats.com/prod/build/images/me.8f1021b0.svg);
}
.fi-me.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/me.4a0e44b9.svg);
}

.fi-mf {
  background-image: url(https://static.bandofboats.com/prod/build/images/mf.9284dddf.svg);
}
.fi-mf.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mf.4b4f663e.svg);
}

.fi-mg {
  background-image: url(https://static.bandofboats.com/prod/build/images/mg.5d294de1.svg);
}
.fi-mg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mg.bdd56d44.svg);
}

.fi-mh {
  background-image: url(https://static.bandofboats.com/prod/build/images/mh.cd62f8f2.svg);
}
.fi-mh.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mh.3263dfcc.svg);
}

.fi-mk {
  background-image: url(https://static.bandofboats.com/prod/build/images/mk.b5eadefb.svg);
}
.fi-mk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mk.d335b1f7.svg);
}

.fi-ml {
  background-image: url(https://static.bandofboats.com/prod/build/images/ml.fa530666.svg);
}
.fi-ml.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ml.abd95c9b.svg);
}

.fi-mm {
  background-image: url(https://static.bandofboats.com/prod/build/images/mm.82321f19.svg);
}
.fi-mm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mm.53de19a4.svg);
}

.fi-mn {
  background-image: url(https://static.bandofboats.com/prod/build/images/mn.7e8cb1ad.svg);
}
.fi-mn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mn.357f0bf4.svg);
}

.fi-mo {
  background-image: url(https://static.bandofboats.com/prod/build/images/mo.8027f57e.svg);
}
.fi-mo.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mo.7a9f442e.svg);
}

.fi-mp {
  background-image: url(https://static.bandofboats.com/prod/build/images/mp.353753fc.svg);
}
.fi-mp.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mp.7b536797.svg);
}

.fi-mq {
  background-image: url(https://static.bandofboats.com/prod/build/images/mq.fe327ace.svg);
}
.fi-mq.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mq.794f4690.svg);
}

.fi-mr {
  background-image: url(https://static.bandofboats.com/prod/build/images/mr.440d1a2f.svg);
}
.fi-mr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mr.d7db7269.svg);
}

.fi-ms {
  background-image: url(https://static.bandofboats.com/prod/build/images/ms.93d49f30.svg);
}
.fi-ms.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ms.3d05f603.svg);
}

.fi-mt {
  background-image: url(https://static.bandofboats.com/prod/build/images/mt.151b602a.svg);
}
.fi-mt.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mt.eabd0a96.svg);
}

.fi-mu {
  background-image: url(https://static.bandofboats.com/prod/build/images/mu.468d5464.svg);
}
.fi-mu.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mu.fd1c498d.svg);
}

.fi-mv {
  background-image: url(https://static.bandofboats.com/prod/build/images/mv.f252e4cb.svg);
}
.fi-mv.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mv.8cb05cc8.svg);
}

.fi-mw {
  background-image: url(https://static.bandofboats.com/prod/build/images/mw.d0647be6.svg);
}
.fi-mw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mw.8b80b615.svg);
}

.fi-mx {
  background-image: url(https://static.bandofboats.com/prod/build/images/mx.bb7c71ea.svg);
}
.fi-mx.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mx.1f3e3f06.svg);
}

.fi-my {
  background-image: url(https://static.bandofboats.com/prod/build/images/my.18a21ce1.svg);
}
.fi-my.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/my.485f7a1e.svg);
}

.fi-mz {
  background-image: url(https://static.bandofboats.com/prod/build/images/mz.fa69ce64.svg);
}
.fi-mz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/mz.f27d6c87.svg);
}

.fi-na {
  background-image: url(https://static.bandofboats.com/prod/build/images/na.121ad7c8.svg);
}
.fi-na.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/na.29e28347.svg);
}

.fi-nc {
  background-image: url(https://static.bandofboats.com/prod/build/images/nc.122e475d.svg);
}
.fi-nc.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/nc.9f2a9401.svg);
}

.fi-ne {
  background-image: url(https://static.bandofboats.com/prod/build/images/ne.82c3626f.svg);
}
.fi-ne.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ne.66cb0cd9.svg);
}

.fi-nf {
  background-image: url(https://static.bandofboats.com/prod/build/images/nf.61dbabec.svg);
}
.fi-nf.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/nf.895fb450.svg);
}

.fi-ng {
  background-image: url(https://static.bandofboats.com/prod/build/images/ng.f29450b9.svg);
}
.fi-ng.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ng.1b98819e.svg);
}

.fi-ni {
  background-image: url(https://static.bandofboats.com/prod/build/images/ni.e78c1b2a.svg);
}
.fi-ni.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ni.e4b67213.svg);
}

.fi-nl {
  background-image: url(https://static.bandofboats.com/prod/build/images/nl.cfe72ae0.svg);
}
.fi-nl.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/nl.c0bdb1a8.svg);
}

.fi-no {
  background-image: url(https://static.bandofboats.com/prod/build/images/no.b9cc4fcd.svg);
}
.fi-no.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/no.c2a3c34c.svg);
}

.fi-np {
  background-image: url(https://static.bandofboats.com/prod/build/images/np.04984547.svg);
}
.fi-np.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/np.8e4f8665.svg);
}

.fi-nr {
  background-image: url(https://static.bandofboats.com/prod/build/images/nr.0d87ce78.svg);
}
.fi-nr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/nr.8ccd0a5d.svg);
}

.fi-nu {
  background-image: url(https://static.bandofboats.com/prod/build/images/nu.2d9c15c3.svg);
}
.fi-nu.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/nu.c4afdde3.svg);
}

.fi-nz {
  background-image: url(https://static.bandofboats.com/prod/build/images/nz.64b16b7e.svg);
}
.fi-nz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/nz.29afa191.svg);
}

.fi-om {
  background-image: url(https://static.bandofboats.com/prod/build/images/om.95a41635.svg);
}
.fi-om.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/om.34a4898c.svg);
}

.fi-pa {
  background-image: url(https://static.bandofboats.com/prod/build/images/pa.86598122.svg);
}
.fi-pa.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pa.f06ec28a.svg);
}

.fi-pe {
  background-image: url(https://static.bandofboats.com/prod/build/images/pe.d64e9732.svg);
}
.fi-pe.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pe.b8e62fe3.svg);
}

.fi-pf {
  background-image: url(https://static.bandofboats.com/prod/build/images/pf.29af7118.svg);
}
.fi-pf.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pf.135056dc.svg);
}

.fi-pg {
  background-image: url(https://static.bandofboats.com/prod/build/images/pg.cc474d6d.svg);
}
.fi-pg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pg.89d38723.svg);
}

.fi-ph {
  background-image: url(https://static.bandofboats.com/prod/build/images/ph.e215bab6.svg);
}
.fi-ph.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ph.f171bffc.svg);
}

.fi-pk {
  background-image: url(https://static.bandofboats.com/prod/build/images/pk.e742c27f.svg);
}
.fi-pk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pk.45854c45.svg);
}

.fi-pl {
  background-image: url(https://static.bandofboats.com/prod/build/images/pl.2b5541c5.svg);
}
.fi-pl.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pl.cd835d05.svg);
}

.fi-pm {
  background-image: url(https://static.bandofboats.com/prod/build/images/pm.f290a4bc.svg);
}
.fi-pm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pm.b56ece89.svg);
}

.fi-pn {
  background-image: url(https://static.bandofboats.com/prod/build/images/pn.b3fc2087.svg);
}
.fi-pn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pn.6db6ac1e.svg);
}

.fi-pr {
  background-image: url(https://static.bandofboats.com/prod/build/images/pr.28689af1.svg);
}
.fi-pr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pr.8885546e.svg);
}

.fi-ps {
  background-image: url(https://static.bandofboats.com/prod/build/images/ps.430e1009.svg);
}
.fi-ps.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ps.3d408db2.svg);
}

.fi-pt {
  background-image: url(https://static.bandofboats.com/prod/build/images/pt.3504a4e4.svg);
}
.fi-pt.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pt.5681b94c.svg);
}

.fi-pw {
  background-image: url(https://static.bandofboats.com/prod/build/images/pw.5df27d3f.svg);
}
.fi-pw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pw.a831460a.svg);
}

.fi-py {
  background-image: url(https://static.bandofboats.com/prod/build/images/py.071e1fd0.svg);
}
.fi-py.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/py.f7b5a568.svg);
}

.fi-qa {
  background-image: url(https://static.bandofboats.com/prod/build/images/qa.74602db5.svg);
}
.fi-qa.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/qa.8f91b751.svg);
}

.fi-re {
  background-image: url(https://static.bandofboats.com/prod/build/images/re.9d72b568.svg);
}
.fi-re.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/re.65fecda0.svg);
}

.fi-ro {
  background-image: url(https://static.bandofboats.com/prod/build/images/ro.33482fcd.svg);
}
.fi-ro.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ro.6d757067.svg);
}

.fi-rs {
  background-image: url(https://static.bandofboats.com/prod/build/images/rs.bd9ca241.svg);
}
.fi-rs.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/rs.e8db134b.svg);
}

.fi-ru {
  background-image: url(https://static.bandofboats.com/prod/build/images/ru.7e04b988.svg);
}
.fi-ru.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ru.c789ea20.svg);
}

.fi-rw {
  background-image: url(https://static.bandofboats.com/prod/build/images/rw.f87cd918.svg);
}
.fi-rw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/rw.2dbaa32b.svg);
}

.fi-sa {
  background-image: url(https://static.bandofboats.com/prod/build/images/sa.9083a464.svg);
}
.fi-sa.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sa.5c9dda39.svg);
}

.fi-sb {
  background-image: url(https://static.bandofboats.com/prod/build/images/sb.22224fa1.svg);
}
.fi-sb.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sb.828b2f11.svg);
}

.fi-sc {
  background-image: url(https://static.bandofboats.com/prod/build/images/sc.4691653c.svg);
}
.fi-sc.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sc.fea26a15.svg);
}

.fi-sd {
  background-image: url(https://static.bandofboats.com/prod/build/images/sd.d7f3b468.svg);
}
.fi-sd.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sd.8df6ee83.svg);
}

.fi-se {
  background-image: url(https://static.bandofboats.com/prod/build/images/se.8b8db9f4.svg);
}
.fi-se.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/se.3dc09b9e.svg);
}

.fi-sg {
  background-image: url(https://static.bandofboats.com/prod/build/images/sg.8b629e7b.svg);
}
.fi-sg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sg.6cc62188.svg);
}

.fi-sh {
  background-image: url(https://static.bandofboats.com/prod/build/images/sh.b0d0251b.svg);
}
.fi-sh.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sh.0de30ebc.svg);
}

.fi-si {
  background-image: url(https://static.bandofboats.com/prod/build/images/si.20c519b4.svg);
}
.fi-si.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/si.ddb0cfd8.svg);
}

.fi-sj {
  background-image: url(https://static.bandofboats.com/prod/build/images/sj.f8f9b215.svg);
}
.fi-sj.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sj.7111b152.svg);
}

.fi-sk {
  background-image: url(https://static.bandofboats.com/prod/build/images/sk.11ccabc6.svg);
}
.fi-sk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sk.10df93d5.svg);
}

.fi-sl {
  background-image: url(https://static.bandofboats.com/prod/build/images/sl.d4cd148f.svg);
}
.fi-sl.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sl.ec1ec422.svg);
}

.fi-sm {
  background-image: url(https://static.bandofboats.com/prod/build/images/sm.b6596da7.svg);
}
.fi-sm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sm.f28af6cd.svg);
}

.fi-sn {
  background-image: url(https://static.bandofboats.com/prod/build/images/sn.5d2dde73.svg);
}
.fi-sn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sn.07ec7ccb.svg);
}

.fi-so {
  background-image: url(https://static.bandofboats.com/prod/build/images/so.178fe496.svg);
}
.fi-so.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/so.8c4f9282.svg);
}

.fi-sr {
  background-image: url(https://static.bandofboats.com/prod/build/images/sr.f281cc96.svg);
}
.fi-sr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sr.45463ee6.svg);
}

.fi-ss {
  background-image: url(https://static.bandofboats.com/prod/build/images/ss.0b0f58ed.svg);
}
.fi-ss.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ss.1c422f30.svg);
}

.fi-st {
  background-image: url(https://static.bandofboats.com/prod/build/images/st.b1de9e52.svg);
}
.fi-st.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/st.950ecdad.svg);
}

.fi-sv {
  background-image: url(https://static.bandofboats.com/prod/build/images/sv.034fabc5.svg);
}
.fi-sv.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sv.1e1cb171.svg);
}

.fi-sx {
  background-image: url(https://static.bandofboats.com/prod/build/images/sx.fc6fe7c5.svg);
}
.fi-sx.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sx.bb9cd2e9.svg);
}

.fi-sy {
  background-image: url(https://static.bandofboats.com/prod/build/images/sy.6ad1ad37.svg);
}
.fi-sy.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sy.06eb07f0.svg);
}

.fi-sz {
  background-image: url(https://static.bandofboats.com/prod/build/images/sz.540261e1.svg);
}
.fi-sz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sz.c265ebae.svg);
}

.fi-tc {
  background-image: url(https://static.bandofboats.com/prod/build/images/tc.e2c8d6cb.svg);
}
.fi-tc.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tc.141a11f0.svg);
}

.fi-td {
  background-image: url(https://static.bandofboats.com/prod/build/images/td.ce35efd0.svg);
}
.fi-td.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/td.16a25e21.svg);
}

.fi-tf {
  background-image: url(https://static.bandofboats.com/prod/build/images/tf.a1e1b04e.svg);
}
.fi-tf.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tf.75f62b39.svg);
}

.fi-tg {
  background-image: url(https://static.bandofboats.com/prod/build/images/tg.2e6abed3.svg);
}
.fi-tg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tg.b80732d6.svg);
}

.fi-th {
  background-image: url(https://static.bandofboats.com/prod/build/images/th.d3dea0ce.svg);
}
.fi-th.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/th.b4262644.svg);
}

.fi-tj {
  background-image: url(https://static.bandofboats.com/prod/build/images/tj.7342efc9.svg);
}
.fi-tj.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tj.6e245795.svg);
}

.fi-tk {
  background-image: url(https://static.bandofboats.com/prod/build/images/tk.dda26036.svg);
}
.fi-tk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tk.16bf538d.svg);
}

.fi-tl {
  background-image: url(https://static.bandofboats.com/prod/build/images/tl.5a92426c.svg);
}
.fi-tl.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tl.2906e09e.svg);
}

.fi-tm {
  background-image: url(https://static.bandofboats.com/prod/build/images/tm.a25a413f.svg);
}
.fi-tm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tm.688215b1.svg);
}

.fi-tn {
  background-image: url(https://static.bandofboats.com/prod/build/images/tn.b66f06d6.svg);
}
.fi-tn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tn.bd942715.svg);
}

.fi-to {
  background-image: url(https://static.bandofboats.com/prod/build/images/to.a7375d4d.svg);
}
.fi-to.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/to.2cb4df0a.svg);
}

.fi-tr {
  background-image: url(https://static.bandofboats.com/prod/build/images/tr.535c328e.svg);
}
.fi-tr.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tr.3a7c1ff2.svg);
}

.fi-tt {
  background-image: url(https://static.bandofboats.com/prod/build/images/tt.faa91be4.svg);
}
.fi-tt.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tt.f3b1780e.svg);
}

.fi-tv {
  background-image: url(https://static.bandofboats.com/prod/build/images/tv.fd2caae1.svg);
}
.fi-tv.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tv.57ef8775.svg);
}

.fi-tw {
  background-image: url(https://static.bandofboats.com/prod/build/images/tw.39308d87.svg);
}
.fi-tw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tw.8ea0b61c.svg);
}

.fi-tz {
  background-image: url(https://static.bandofboats.com/prod/build/images/tz.bec1e1d6.svg);
}
.fi-tz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/tz.5ca0246e.svg);
}

.fi-ua {
  background-image: url(https://static.bandofboats.com/prod/build/images/ua.423c68f9.svg);
}
.fi-ua.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ua.af1d11da.svg);
}

.fi-ug {
  background-image: url(https://static.bandofboats.com/prod/build/images/ug.075b7772.svg);
}
.fi-ug.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ug.8296b913.svg);
}

.fi-um {
  background-image: url(https://static.bandofboats.com/prod/build/images/um.9efb46d3.svg);
}
.fi-um.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/um.a57097a4.svg);
}

.fi-us {
  background-image: url(https://static.bandofboats.com/prod/build/images/us.912cb4a8.svg);
}
.fi-us.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/us.9acbcf10.svg);
}

.fi-uy {
  background-image: url(https://static.bandofboats.com/prod/build/images/uy.2515d737.svg);
}
.fi-uy.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/uy.cb5fcc05.svg);
}

.fi-uz {
  background-image: url(https://static.bandofboats.com/prod/build/images/uz.7ed400a6.svg);
}
.fi-uz.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/uz.15a9a22c.svg);
}

.fi-va {
  background-image: url(https://static.bandofboats.com/prod/build/images/va.88dc9bbc.svg);
}
.fi-va.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/va.1ebc2739.svg);
}

.fi-vc {
  background-image: url(https://static.bandofboats.com/prod/build/images/vc.696f1551.svg);
}
.fi-vc.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/vc.007dc890.svg);
}

.fi-ve {
  background-image: url(https://static.bandofboats.com/prod/build/images/ve.8654417d.svg);
}
.fi-ve.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ve.566e1086.svg);
}

.fi-vg {
  background-image: url(https://static.bandofboats.com/prod/build/images/vg.4c507ebc.svg);
}
.fi-vg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/vg.e2e17502.svg);
}

.fi-vi {
  background-image: url(https://static.bandofboats.com/prod/build/images/vi.48bf3e00.svg);
}
.fi-vi.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/vi.4dd97d36.svg);
}

.fi-vn {
  background-image: url(https://static.bandofboats.com/prod/build/images/vn.e80173a9.svg);
}
.fi-vn.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/vn.7601c8ac.svg);
}

.fi-vu {
  background-image: url(https://static.bandofboats.com/prod/build/images/vu.6d2ce93c.svg);
}
.fi-vu.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/vu.5bd5de68.svg);
}

.fi-wf {
  background-image: url(https://static.bandofboats.com/prod/build/images/wf.2a313731.svg);
}
.fi-wf.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/wf.311c4e73.svg);
}

.fi-ws {
  background-image: url(https://static.bandofboats.com/prod/build/images/ws.314e33c2.svg);
}
.fi-ws.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ws.f06e3a39.svg);
}

.fi-ye {
  background-image: url(https://static.bandofboats.com/prod/build/images/ye.948f27c4.svg);
}
.fi-ye.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ye.822b8bf2.svg);
}

.fi-yt {
  background-image: url(https://static.bandofboats.com/prod/build/images/yt.433b076a.svg);
}
.fi-yt.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/yt.db71201b.svg);
}

.fi-za {
  background-image: url(https://static.bandofboats.com/prod/build/images/za.57ed656a.svg);
}
.fi-za.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/za.35fc70ec.svg);
}

.fi-zm {
  background-image: url(https://static.bandofboats.com/prod/build/images/zm.8e1dfbaa.svg);
}
.fi-zm.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/zm.562ac485.svg);
}

.fi-zw {
  background-image: url(https://static.bandofboats.com/prod/build/images/zw.f13ad27c.svg);
}
.fi-zw.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/zw.e539c649.svg);
}

.fi-arab {
  background-image: url(https://static.bandofboats.com/prod/build/images/arab.1b9aa1f0.svg);
}
.fi-arab.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/arab.282a837b.svg);
}

.fi-cefta {
  background-image: url(https://static.bandofboats.com/prod/build/images/cefta.159b8240.svg);
}
.fi-cefta.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cefta.4247899e.svg);
}

.fi-cp {
  background-image: url(https://static.bandofboats.com/prod/build/images/cp.f6b10f55.svg);
}
.fi-cp.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/cp.6c0badec.svg);
}

.fi-dg {
  background-image: url(https://static.bandofboats.com/prod/build/images/dg.6f63b848.svg);
}
.fi-dg.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/dg.ce78983d.svg);
}

.fi-eac {
  background-image: url(https://static.bandofboats.com/prod/build/images/eac.2f2a6145.svg);
}
.fi-eac.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/eac.d49194c2.svg);
}

.fi-es-ct {
  background-image: url(https://static.bandofboats.com/prod/build/images/es-ct.ae2d1ab9.svg);
}
.fi-es-ct.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/es-ct.9d52c0a5.svg);
}

.fi-es-ga {
  background-image: url(https://static.bandofboats.com/prod/build/images/es-ga.b5043ad2.svg);
}
.fi-es-ga.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/es-ga.5ae204c7.svg);
}

.fi-es-pv {
  background-image: url(https://static.bandofboats.com/prod/build/images/es-pv.bc671336.svg);
}
.fi-es-pv.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/es-pv.e0f6252a.svg);
}

.fi-eu {
  background-image: url(https://static.bandofboats.com/prod/build/images/eu.c9d8d63e.svg);
}
.fi-eu.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/eu.8224e426.svg);
}

.fi-gb-eng {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb-eng.ee357143.svg);
}
.fi-gb-eng.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb-eng.7b249f56.svg);
}

.fi-gb-nir {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb-nir.d60ecd94.svg);
}
.fi-gb-nir.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb-nir.7173e164.svg);
}

.fi-gb-sct {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb-sct.5edc6d75.svg);
}
.fi-gb-sct.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb-sct.5e8518f9.svg);
}

.fi-gb-wls {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb-wls.4abd7bc9.svg);
}
.fi-gb-wls.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/gb-wls.910c0fb3.svg);
}

.fi-ic {
  background-image: url(https://static.bandofboats.com/prod/build/images/ic.500d4209.svg);
}
.fi-ic.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/ic.5217cbc3.svg);
}

.fi-pc {
  background-image: url(https://static.bandofboats.com/prod/build/images/pc.d669f78a.svg);
}
.fi-pc.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/pc.1fac7748.svg);
}

.fi-sh-ac {
  background-image: url(https://static.bandofboats.com/prod/build/images/sh-ac.bb210c27.svg);
}
.fi-sh-ac.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sh-ac.2f733db9.svg);
}

.fi-sh-hl {
  background-image: url(https://static.bandofboats.com/prod/build/images/sh-hl.7ba1be5f.svg);
}
.fi-sh-hl.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sh-hl.6c91c7e4.svg);
}

.fi-sh-ta {
  background-image: url(https://static.bandofboats.com/prod/build/images/sh-ta.17bd9770.svg);
}
.fi-sh-ta.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/sh-ta.5dea19f1.svg);
}

.fi-un {
  background-image: url(https://static.bandofboats.com/prod/build/images/un.05b44fa6.svg);
}
.fi-un.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/un.5655b589.svg);
}

.fi-xk {
  background-image: url(https://static.bandofboats.com/prod/build/images/xk.80de9468.svg);
}
.fi-xk.fis {
  background-image: url(https://static.bandofboats.com/prod/build/images/xk.3f0c92b8.svg);
}

button.btn-outline-primary:not(:hover),
.btn.btn-outline-primary:not(:hover),
a.btn-outline-primary:not(:hover) {
  background: #ffffff !important;
}

a:focus-visible {
  text-decoration: underline !important;
  outline: var(--bs-steelblue) solid thin;
  outline-offset: 5px;
}
